import React from 'react';

export default ({ increment, decrement, value, label }) => {
  return (
    <div className="flex p-4 border-b">
      <div className="w-12 text-sm">
        <h4 className="text-grey-darkest">
          {label.charAt(0).toUpperCase() + label.slice(1)}
        </h4>
        {label === 'adult' && <h5 className="text-grey-darker mt-1">Adult</h5>}
        {label === 'child' && <h5 className="text-grey-darker mt-1">2-11</h5>}
        {label === 'infant' && <h5 className="text-grey-darker mt-1">0-2</h5>}
      </div>
      <div className="h-6 mt-1 ml-auto flex">
        <button
          type="button"
          className="ml-2 rounded-full border w-6 hover:bg-grey-lightest bg-white hover:text-blue"
          onClick={decrement}
        >
          -
        </button>
        <p className="px-2 w-8 text-center">{value}</p>
        <button
          type="button"
          className="mr-2 rounded-full border w-6 hover:bg-grey-lightest bg-white hover:text-blue"
          onClick={increment}
        >
          +
        </button>
      </div>
    </div>
  );
};
