import React, { useState } from 'react';

function CallNow() {
  const [closed, setClosed] = useState(false);

  return (
    !closed && (
      <div
        className="py-1 md:hidden fixed pin-b text-white w-full z-20 border border-white"
        style={{ backgroundColor: '#0054a6' }}
      >
        <div className="flex mx-auto">
          <div
            className="px-2 -pt-1 text-2xl text-grey-light font-bold"
            onClick={() => setClosed(true)}
          >
            ×
          </div>
          <div className="flex p-2 mr-auto ml-4">
            <i className="material-icons text-4xl mr-2">phone</i>
            <div className="mr-4">
              <p className="text-xs">Call now to book</p>
              <p className="text-base font-bold">1-888-221-6550</p>
            </div>
            <a
              className="px-3 my-auto text-sm font-bold bg-blue text-white no-underline uppercase border border-white"
              style={{
                transition: 'all 125ms ease-in',
                padding: '0.33rem 0.75rem 0.33rem 0.75rem'
              }}
              href="tel:1-888-221-6550"
            >
              Call Now
            </a>
          </div>
        </div>
      </div>
    )
  );
}

export default CallNow;
