import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Quote from './components/Quote/Quote';
import Modal from './components/Home/Modal';
import Navbar from './components/Navbar/Navbar';
import CallNow from './components/CallNow';
import Footer from './components/Footer/Footer';

// const Navbar = lazy(() => import('./components/Navbar/Navbar'));
// const Modal = lazy(() => import('./components/Home/Modal'));
const Home = lazy(() => import('./components/Home/Home'));
const About = lazy(() => import('./components/About/About'));
// const CallNow = lazy(() => import('./components/CallNow'));
const Corporate = lazy(() => import('./components/Corporate/Corporate'));
const Blog = lazy(() => import('./components/Blog/Blog'));
const BlogPost = lazy(() => import('./components/Blog/BlogPost'));
const FirstClass = lazy(() => import('./components/Services/FirstClass'));
const BusinessClass = lazy(() => import('./components/Services/BusinessClass'));
const Contact = lazy(() => import('./components/Contact/Contact'));
// const Footer = lazy(() => import('./components/Footer/Footer'));
const ToS = lazy(() => import('./components/Footer/ToS'));
const Privacy = lazy(() => import('./components/Footer/Privacy'));
// const Quote = lazy(() => import('./components/Quote/Quote'));
const QuoteSuccess = lazy(() => import('./components/Quote/QuoteSuccess'));

export const firstClassRoutes = [
  '/First-Class/airfare',
  '/First-Class/Discounted',
  '/First_Class/Ticket',
  '/First-Class/Trip',
  '/First-Class/Discounted‬',
  '/first-class-tickets.html'
];

export const businessClassRoutes = [
  '/Business-Class/airfare',
  '/Business-Class/Discounted',
  '/Business_Class/Ticket',
  '/Business-Class/Trip',
  '/Business-Class/Discounted‬',
  '/business-class-tickets.html'
];

function App() {
  return (
    <Router>
      <div style={{ fontFamily: ['Open Sans', 'sans-serif'] }}>
        <Modal />
        <Navbar />
        <Route
          path={/^(?!.*thank-you\.html).*$/}
          render={({ location }) => <Quote location={location} />}
        />
        <Suspense fallback={<div style={{ height: '512px' }}>Loading...</div>}>
          <div className="">
            <Route exact path={'/'} render={() => <Home />} />
            <Route exact path={'/about.html'} render={() => <About />} />
            {firstClassRoutes.map(p => (
              <Route exact path={p} render={() => <FirstClass />} />
            ))}
            {businessClassRoutes.map(p => (
              <Route exact path={p} render={() => <BusinessClass />} />
            ))}
            <Route exact path={'/contact.html'} render={() => <Contact />} />

            <Route
              exact
              path={'/corporate.html'}
              render={() => <Corporate />}
            />
            <Route exact path={'/blog'} render={() => <Blog />} />
            <Route
              exact
              path={'/terms-of-service.html'}
              render={() => <ToS />}
            />
            <Route
              exact
              path={'/privacy-policy.html'}
              render={() => <Privacy />}
            />
            <Route
              path={'/blog/:id'}
              render={({ match }) => <BlogPost post={match.params.id} />}
            />
          </div>
          <Route
            exact
            path={'/thank-you.html'}
            render={() => <QuoteSuccess />}
          />
        </Suspense>
        <CallNow />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
