import React, { Suspense, useState, useEffect } from 'react';

import slider_1 from './img/jpg/slider-1.jpg';
import slider_2 from './img/jpg/slider-2.jpg';
import about_us from './img/jpg/about-us.jpg';
import blog from './img/jpg/blog.jpg';
import contact from './img/jpg/contact.jpg';
import corporate from './img/jpg/corporate.jpg';
import services from './img/jpg/services.jpg';

import slider_1_webp from './img/webp/slider-1.webp';
import slider_2_webp from './img/webp/slider-2.webp';
import about_us_webp from './img/webp/about-us.webp';
import blog_webp from './img/webp/blog.webp';
import contact_webp from './img/webp/contact.webp';
import corporate_webp from './img/webp/corporate.webp';
import services_webp from './img/webp/services.webp';

const slideImages = [slider_1, slider_2];
const sliderImagesWebp = [slider_1_webp, slider_2_webp];
const carouselImages = {
  '/': [slider_1, slider_2],
  '/terms-of-service.html': [slider_1, slider_2],
  '/privacy-policy.html': [slider_1, slider_2],
  '/about.html': [about_us],
  '/blog': [blog],
  '/contact.html': [contact],
  '/corporate.html': [corporate],
  '/first-class-tickets.html': [services],
  '/business-class-tickets.html': [services]
};
const carouselImagesWebp = {
  '/': [slider_1_webp, slider_2_webp],
  '/terms-of-service.html': [slider_1_webp, slider_2_webp],
  '/privacy-policy.html': [slider_1_webp, slider_2_webp],
  '/about.html': [about_us_webp],
  '/blog': [blog_webp],
  '/contact.html': [contact_webp],
  '/corporate.html': [corporate_webp],
  '/first-class-tickets.html': [services_webp],
  '/business-class-tickets.html': [services_webp]
};

const QuoteCarouselPicture = React.lazy(() => import('./QuoteCarouselPicture'));

export default props => {
  const [currentIndex, setCurrentIndex] = useState(0);
  let url = `/${props.location.pathname.split('/')[1].trim()}`;
  if (carouselImages[url] === undefined) {
    url = '/';
  }
  useEffect(() => {
    let timeout = setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % carouselImages[url].length);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [currentIndex, url]);

  useEffect(() => {
    setCurrentIndex(0);
  }, [url]);

  function canUseWebP() {
    var elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }

    // very old browser like IE 8, canvas not supported
    return false;
  }

  return (
    <section
      className="relative h-full w-full mt-16"
      style={{ minHeight: '660px' }}
    >
      <div className="absolute h-full w-full" style={{ zIndex: '-1' }}>
        {carouselImages[url].map((imgUrl, index) => (
          <div
            key={imgUrl}
            id="carousel"
            className={`${
              index === currentIndex ? 'opacity-100' : 'opacity-0'
            } absolute w-full h-full md:p-8 p-2`}
            style={{
              backgroundImage: `${
                canUseWebP()
                  ? `url(${carouselImagesWebp[url][index]}`
                  : `url(${imgUrl})`
              }`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              transition: 'all 1s ease-in-out'
            }}
          ></div>
        ))}
      </div>
      {props.children}
    </section>
  );
};
