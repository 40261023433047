import React from 'react';
import { Field } from 'formik';
import FormIcon from '../FormIcon';

export default function TextField(props) {
  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="flex">
      <FormIcon error={props.error} icon={props.icon} />
      <Field
        name={props.name}
        type={props.type}
        placeholder={
          props.placeholder ? props.placeholder : capitalize(props.name)
        }
        className={`${
          props.error
            ? 'border-2 border-red-light border-l-0'
            : 'border border-blue border-l-0'
        } py-3 w-full rounded-r pl-2`}
        aria-label={props.ariaLabel ? props.ariaLabel : props.name}
      />
    </div>
  );
}
