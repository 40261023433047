import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import ServicesNav from './ServicesNav';
import WebpImgWithFallback from '../WebpImgWithFallback';
import NavbarPhoneNumbers from './NavbarPhoneNumbers';
import logo from '../images/goflyfirst-logo.svg';
import ModalCallNow from '../Home/ModalCallNow';
import Hamburger from './Hamburger';
import { useOutsideClick } from 'hooks/useOutsideClick';

import accredited_webp from './img/webp/accredited-business.webp';
import accredited_jpg from './img/jpg/accredited-business.jpg';
import call_now from '../Home/img/jpg/mobile-callnow-header.jpg';

function Navbar(props) {
  const [open, setOpen] = useState(false);
  const [logoWidth, setLogoWidth] = useState(
    window.innerWidth < 768 ? '215px' : '325px'
  );
  const [showModal, setShowModal] = useState(false);
  const navbarRef = useRef(null);

  useOutsideClick(navbarRef, () => setOpen(false));

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setLogoWidth('215px');
      } else {
        setLogoWidth('325px');
      }
    };

    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setOpen(false);
  }, [props.location.pathname]);

  return (
    <div ref={navbarRef} className="w-full pin-t fixed bg-white z-30">
      <ModalCallNow showModal={showModal} setShowModal={setShowModal} />
      <nav
        className="lg:flex lg:py-0 pt-3 w-full lg:mx-auto md:px-4 bg-white border-b"
        style={{ minHeight: '71px' }}
      >
        <div className="flex">
          <div
            onClick={() => setOpen(!open)}
            className="block lg:hidden rounded mt-2 ml-4"
          >
            {open ? (
              <div
                className="text-5xl font-bold -mt-4"
                style={{ width: '24px' }}
              >
                ×
              </div>
            ) : (
              <Hamburger />
            )}
          </div>
          <NavLink
            onClick={() => setOpen(!open)}
            style={{ maxWidth: logoWidth, minWidth: '183px' }}
            className="lg:ml-auto m-2 lg:mt-auto lg:mb-2"
            to={'/'}
          >
            <img src={logo} alt="GoFlyFirst" />
          </NavLink>
          <div
            onClick={() => setShowModal(true)}
            className="lg:hidden mt-2 mr-4 ml-auto"
          >
            <img style={{ width: '115px' }} src={call_now} alt="" />
          </div>
        </div>
        <div
          className={`${
            open ? 'block' : 'hidden'
          } lg:px-4 lg:mt-6 lg:flex items-center md:text-center mx-auto`}
          style={{ minWidth: '650px' }}
        >
          <NavLink
            onClick={() => setOpen(!open)}
            className="gff-nav-link md:px-1 px-2 flex-grow lg:pb-0 pb-4 md:border-none border-b md:text-base text-grey-darkest block hover:text-blue"
            to={'/'}
          >
            Home
          </NavLink>
          <NavLink
            onClick={() => setOpen(!open)}
            activeClassName="text-blue"
            className="gff-nav-link md:px-1 px-2 flex-grow lg:pb-0 pb-4 md:border-none border-b md:text-base text-grey-darkest block hover:text-blue"
            to={'/about.html'}
            style={{ minWidth: '103px' }}
          >
            About Us
          </NavLink>
          <ServicesNav />
          <NavLink
            onClick={() => setOpen(!open)}
            activeClassName="text-blue"
            className="gff-nav-link md:px-1 px-2 flex-grow lg:pb-0 pb-4 md:border-none border-b md:text-base text-grey-darkest block hover:text-blue"
            to={'/corporate.html'}
          >
            Corporate
          </NavLink>
          <NavLink
            onClick={() => setOpen(!open)}
            activeClassName="text-blue"
            className="gff-nav-link md:px-1 px-2 flex-grow lg:pb-0 pb-4 md:border-none border-b md:text-base text-grey-darkest block hover:text-blue"
            to={'/blog'}
          >
            Blog
          </NavLink>
          <NavLink
            onClick={() => setOpen(!open)}
            activeClassName="text-blue"
            className="gff-nav-link md:px-1 px-2 flex-grow lg:pb-0 pb-4 md:text-base text-grey-darkest block hover:text-blue"
            to={'/contact.html'}
          >
            Contact
          </NavLink>
        </div>
        <div className="flex items-center" style={{ minWidth: '508px' }}>
          <div className="w-1/2 ml-4">
            <NavbarPhoneNumbers />
          </div>
          <WebpImgWithFallback
            className="w-1/2 ml-4 h-10 xl:visible xl:opacity-100 xl:block hidden opacity-0 invisible"
            webp={accredited_webp}
            fallback={accredited_jpg}
            alt="Accredited business logo"
          />
        </div>
      </nav>
    </div>
  );
}

export default withRouter(Navbar);
