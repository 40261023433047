import React, { useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { useDebounce } from 'hooks/useDebounce';
import FormIcon from '../FormIcon';
import destinations from 'data/destinations.json';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

const flags = importAll(
  require.context('../img/flags', false, /\.(png|jpe?g|svg)$/)
);

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function DestinationField({
  placeholder,
  name,
  handleBlur,
  ariaLabel,
  setFieldValue,
  ...props
}) {
  const [userInput, setUserInput] = useState('');
  const debouncedValue = useDebounce(userInput, 250);

  function handleChange(e) {
    setUserInput(e.target.value);
  }

  const { error } = props;

  return (
    <div className="flex rounded w-full">
      <FormIcon error={error} icon="airplanemode_active" />
      <Autocomplete
        {...props}
        inputProps={{
          placeholder: placeholder ? placeholder : capitalize(name),
          name: name,
          onBlur: handleBlur,
          'aria-label': ariaLabel
        }}
        getItemValue={item => item.label}
        items={destinations}
        shouldItemRender={item =>
          debouncedValue &&
          item.label.toLowerCase().indexOf(debouncedValue.toLowerCase()) > -1
        }
        renderItem={(item, isHighlighted) => {
          return (
            <div
              key={item.key}
              className={`${
                isHighlighted ? 'bg-grey-lighter' : 'bg-white'
              } p-2 flex border-b `}
            >
              <img
                src={flags[`${item.iso.toLowerCase()}.png`]}
                className="w-6 h-4 mr-2"
                alt=""
              />
              <div>{item.label}</div>
            </div>
          );
        }}
        renderMenu={(items, value, style) => {
          return (
            <div
              className="w-full absolute overflow-auto z-10 border-b border-l border-r shadow-md"
              style={{ maxHeight: '230px', zIndex: 20 }}
              children={items}
            />
          );
        }}
        renderInput={props => (
          <input
            className={`${
              error
                ? 'border-2 border-red-light border-l-0'
                : 'border border-blue border-l-0'
            } py-3 rounded-r w-full pl-2`}
            style={{ WebkitAppearance: 'none' }}
            {...props}
          />
        )}
        wrapperStyle={null}
        wrapperProps={{ className: 'w-full relative' }}
        value={userInput}
        onChange={e => {
          handleChange(e);
          setFieldValue(name, e.target.value);
        }}
        onSelect={val => {
          setUserInput(val);
          setFieldValue(name, val);
        }}
      />
    </div>
  );
}
