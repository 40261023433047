import React, { Suspense } from 'react';
const Image = React.lazy(() => import('./Image'));

export default props => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Image {...props} />
    </Suspense>
  );
};
