import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default props => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className="cursor-pointer flex-grow"
      onMouseOver={() => setVisible(true)}
      onMouseOut={() => setVisible(false)}
      onClick={() => setVisible(visible ? !visible : true)}
    >
      <div className="gff-nav-link md:px-1 px-2 lg:pb-2 md:mt-2 pb-4 md:border-none border-b flex hover:text-blue text-black">
        <p className="md:text-base no-underline cursor-pointer uppercase md:ml-auto">
          Services{' '}
        </p>
        <i className="-my-1 material-icons mr-auto">keyboard_arrow_down</i>
      </div>
      <div
        className={`lg:absolute lg:shadow gff-nav-dropdown ${
          !visible ? 'lg:opacity-0 lg:invisible hidden' : 'opacity-100 visible'
        } mt-0 text-left`}
        style={{ right: '' }}
      >
        <NavLink
          className="gff-nav-dropdown-item hover:bg-grey-lightest"
          style={{ right: '0px' }}
          to={'/first-class-tickets.html'}
        >
          First Class Tickets
        </NavLink>
        <NavLink
          className="gff-nav-dropdown-item hover:bg-grey-lightest"
          to={'/business-class-tickets.html'}
        >
          Business Class Tickets
        </NavLink>
      </div>
    </div>
  );
};
