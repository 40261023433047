import React, { Suspense, useState, useEffect, useRef } from 'react';
import QuoteForm from './Form/QuoteForm';
import WebpImgWithFallback from '../WebpImgWithFallback';

import partnerlogos_webp from './img/webp/partnerlogos.webp';
import logos_webp from './img/webp/logos.webp';
import partnerlogos_png from './img/png/partnerlogos.png';
import logos_png from './img/png/logos.png';

// const QuoteCarousel = React.lazy(() => import('./QuoteCarousel'));
import QuoteCarousel from './QuoteCarousel';
import { businessClassRoutes, firstClassRoutes } from '../../App';

export default function Quote(props) {
  const [height, setHeight] = useState(window.innerWidth < 768 ? 921 : 640);
  const quoteFormDiv = useRef();

  useEffect(() => {
    // console.log(props.location.pathname, '/', props.location.pathname === '/')
    const pathnames = [
      '/',
      '/thank-you.html',
      '/business-class-tickets.html',
      '/first-class-tickets.html',
      ...firstClassRoutes,
      ...businessClassRoutes
    ];
    if (pathnames.indexOf(props.location.pathname) > -1) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, quoteFormDiv.current.clientHeight);
    }
  }, [props.location]);

  useEffect(() => {
    const handleResize = () => setHeight(quoteFormDiv.current.clientHeight);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div className="mx-auto">
      <QuoteCarousel location={props.location}>
        <div className="md:p-8 z-10 container mx-auto" ref={quoteFormDiv}>
          <h1 className="md:m-8 md:text-4xl m-2 mt-1 md:pt-0 pt-2 md:mt-8 text-2xl text-white font-normal text-center">
            Get Discounted Fares for First and Business Class
          </h1>
          <QuoteForm location={props.location} />
          <div className="text-center">
            <WebpImgWithFallback
              imgClass="lg:opacity-100 opacity-0 text-center my-8"
              webp={partnerlogos_webp}
              fallback={partnerlogos_png}
              alt={'Partner logos'}
            />
            <WebpImgWithFallback
              imgClass="lg:opacity-100 opacity-0 text-center mt-8 mb-16"
              webp={logos_webp}
              fallback={logos_png}
              alt={'Accredited Logos'}
            />
          </div>
        </div>
      </QuoteCarousel>
    </div>
  );
}
