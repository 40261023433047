import React from 'react';

export default function FormIcon({ icon, error }) {
  return (
    <i
      className={`${
        error ? 'border-red-light border-2' : 'border border-blue'
      } py-2 px-2 rounded-l bg-white text-blue material-icons border-r-0`}
    >
      {icon}
    </i>
  );
}
