import React from 'react';

export default function Hamburger() {
  return (
    <div>
      <div
        className={`bg-blue-darker w-6 mx-auto mt-1 rounded`}
        style={{ height: '4px' }}
      />
      <div
        className={`bg-blue-darker w-6 mx-auto mt-1 rounded`}
        style={{ height: '4px' }}
      />
      <div
        className={`bg-blue-darker w-6 mx-auto mt-1 rounded`}
        style={{ height: '4px' }}
      />
    </div>
  );
}
