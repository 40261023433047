import React from 'react';

export default ({ values, setFieldValue }) => {
  return (
    <div className="flex text-xs">
      <button
        className={`${
          values.flight === 'roundTrip'
            ? 'bg-blue text-white'
            : 'bg-white text-grey-darker hover:bg-grey-lighter'
        } py-2 px-3 mr-1 rounded  uppercase`}
        onClick={() => setFieldValue('flight', 'roundTrip')}
        type="button"
      >
        Round Trip
      </button>
      <button
        className={`${
          values.flight === 'oneWay'
            ? 'bg-blue text-white'
            : 'bg-white text-grey-darker hover:bg-grey-lighter'
        } py-2 px-3 mr-1 rounded  uppercase`}
        onClick={() => setFieldValue('flight', 'oneWay')}
        type="button"
      >
        One Way
      </button>
      <button
        className={`${
          values.flight === 'multiCity'
            ? 'bg-blue text-white'
            : 'bg-white text-grey-darker hover:bg-grey-lighter'
        } py-2 px-3 mr-1 rounded  uppercase`}
        onClick={() => setFieldValue('flight', 'multiCity')}
        type="button"
      >
        Multi City
      </button>
    </div>
  );
};
