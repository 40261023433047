const ContactUSA = "888-221-6550";
const ContactAUS = "02 80058470";
const ContactHKG = "852-8192 5250";

// FIXME: make all contact text point to this object instead of hardcoding
const ContactInfo = {
    USA: {
        hyphenatedText: `1-${ContactUSA}`,
        simpleText: `1 ${ContactUSA}`,
        href: `tel:1-${ContactUSA}`,
    },
    AUS: {
        simpleText: ContactAUS,
        href: `tel:${ContactAUS}`,
    },
    HKG: {
        simpleText: ContactHKG,
        href: `tel:${ContactHKG}`,
    },
};

export default ContactInfo;
