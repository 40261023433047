import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import FormIcon from '../FormIcon';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './DateField.css';
import moment from 'moment';

import { SingleDatePicker } from 'react-dates';

export default function PickDate(props) {
  const [currentDate, setCurrentDate] = useState(null);
  const [focus, setFocus] = useState(null);
  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div className="flex rounded">
      <FormIcon error={props.error} icon="calendar_today" />
      <div
        className={`rounded-r ${
          props.error ? 'border-2 border-l-0 border-red-light' : ''
        }`}
      >
        <SingleDatePicker
          date={props.value} // momentPropTypes.momentObj or null
          onDateChange={date => props.setFieldValue(props.name, date)} // PropTypes.func.isRequired
          focused={focus} // PropTypes.bool
          onFocusChange={({ focused }) => setFocus(focused)} // PropTypes.func.isRequired
          id={props.name} // PropTypes.string.isRequired,
          keepFocusOnInput={false}
          keepOpenOnDateSelect={false}
          placeholder={
            props.placeholder ? props.placeholder : capitalize(props.name)
          }
          numberOfMonths={1}
          verticalSpacing={1}
          block
          readOnly
          required={props.required}
          isOutsideRange={date => date.isBefore(props.minDate)}
          onClose={() => props.setFieldTouched(props.name, true)}
        />
      </div>
    </div>
  );
}
