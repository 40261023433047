import React, { useState, useEffect } from 'react';
import ModalLady from './ModalLady';
import ModalCallNow from './ModalCallNow';

export default () => {
  const [showModal, setShowModal] = useState(false);
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setShowModal(true);
    }, 15000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', e => {
      if (e.keyCode === 27) {
        setShowModal(false);
        setClosed(true);
      }
    });
    return document.removeEventListener('keydown', e => {
      if (e.keyCode === 27) {
        setShowModal(false);
        setClosed(true);
      }
    });
  }, []);

  if (closed) {
    return null;
  }

  return (
    <div
      className={`${
        showModal
          ? 'fixed opacity-100 visible -mt-16 block'
          : 'opacity-0 invisible'
      } w-full h-full z-50`}
    >
      <div
        className={`${
          showModal ? 'opacity-50 visible' : 'opacity-0 invisible'
        } absolute w-full h-screen bg-black`}
        style={{ transition: 'all 125ms ease-in' }}
        onClick={() => {
          setShowModal(false);
          setClosed(true);
        }}
      />
      <div
        className={`${
          showModal ? 'opacity-100 visible' : 'opacity-0 invisible -mt-32'
        } absolute pin mx-auto flex md:items-center z-10`}
        style={{ transition: 'all 500ms ease-in', maxWidth: '900px' }}
      >
        <ModalLady setShowModal={setShowModal} setClosed={setClosed} />
      </div>
    </div>
  );
};
