import React, { useState, useEffect, useRef } from 'react';
import WebpImgWithFallback from '../WebpImgWithFallback';

import usa_webp from '../Home/img/webp/usa.webp';
import aus_webp from '../Home/img/webp/aus.webp';
import hkg_webp from '../Home/img/webp/hkg.webp';

import usa_jpg from '../Home/img/jpg/usa.jpg';
import aus_jpg from '../Home/img/jpg/aus.jpg';
import hkg_jpg from '../Home/img/jpg/hkg.jpg';

const flagNumbers = [
  { flag_webp: usa_webp, flag_jpg: usa_jpg, number: '1 888-221-6550' },
  { flag_webp: aus_webp, flag_jpg: aus_jpg, number: '02 0058470' },
  { flag_webp: hkg_webp, flag_jpg: hkg_jpg, number: '852-8912 5250' }
];

export default () => {
  const [open, setOpen] = useState(false);
  const [currentFlagNumber, setCurrentFlagNumber] = useState(flagNumbers[0]);
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <div
      ref={node}
      className="xl:visible xl:opacity-100 xl:block hidden opacity-0 invisible"
    >
      <p className="text-sm ml-16 mb-2">Call 24/7 (TOLL FREE)</p>
      <div className="flex">
        <div className="flex" onClick={() => setOpen(!open)}>
          <WebpImgWithFallback
            imgClass="w-8 h-5"
            webp={currentFlagNumber.flag_webp}
            fallback={currentFlagNumber.flag_jpg}
            alt="Flag"
          />
          <i
            className={`${
              open ? 'text-black' : 'text-grey'
            } -mt-px material-icons`}
          >
            keyboard_arrow_down
          </i>
        </div>
        <a
          href={`tel:${currentFlagNumber.number}`}
          className="ml-2 text-red text-lg no-underline"
        >
          {currentFlagNumber.number}
        </a>
      </div>
      {open && (
        <div className="absolute bg-white shadow rounded">
          {flagNumbers.map((flagNumber, index) => (
            <div
              className="flex my-1 hover:bg-grey-lighter px-4 py-1"
              onClick={() => {
                setCurrentFlagNumber(flagNumbers[index]);
                setOpen(false);
              }}
            >
              <WebpImgWithFallback
                imgClass="w-6 h-4"
                webp={flagNumber.flag_webp}
                fallback={flagNumber.flag_jpg}
                alt="Flag"
              />
              <p className="ml-2 text-sm">{flagNumber.number}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
