import React from 'react';
import { Field } from 'formik';

export default () => {
  return (
    <div className="flex py-4 px-4 w-full border-b">
      <p className="px-1 text-sm rounded mt-2 bg-white">Class</p>
      <Field
        component="select"
        name="class"
        className="px-1 py-1 text-sm rounded border md:mt-0 ml-auto bg-white"
      >
        <option value="first">First</option>
        <option value="business">Business</option>
        <option value="premium economy">Premium Economy</option>
        <option value="economy">Economy</option>
      </Field>
    </div>
  );
};
