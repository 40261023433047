import React, { useState, useEffect, useRef } from 'react';
import PassengerFieldButtons from './PassengerFieldButtons';
import FlightClassField from './FlightClassField';
import FormIcon from '../FormIcon';

export default ({ values, setFieldValue }) => {
  const [open, setOpen] = useState(false);
  const totalPassengers = Object.keys(values.passengers).reduce(
    (sum, key) => (sum += parseInt(values.passengers[key])),
    0
  );
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <div className="md:ml-1 md:mr-2 mx-2 w-full" ref={node}>
      <div className="flex my-2">
        <FormIcon icon="airline_seat_recline_normal" />
        <div
          className="flex w-full text-grey-dark bg-white rounded-r py-3 truncate cursor-pointer border border-blue border-l-0"
          onClick={() => setOpen(!open)}
        >
          <p className="w-full text-sm truncate">
            {totalPassengers}{' '}
            {totalPassengers === 1 ? 'Passenger ' : 'Passengers'}
          </p>
          <i className="-my-1 material-icons">{`${
            open ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
          }`}</i>
        </div>
        {open && (
          <div
            style={{ zIndex: 20 }}
            className="lg:ml-8 md:-ml-32 mt-10 bg-white border shadow rounded absolute z-10 w-64"
          >
            <FlightClassField />
            <PassengerFieldButtons
              increment={() =>
                setFieldValue('passengers.adult', values.passengers.adult + 1)
              }
              decrement={() =>
                setFieldValue(
                  'passengers.adult',
                  values.passengers.adult > 1 ? values.passengers.adult - 1 : 1
                )
              }
              value={values.passengers.adult}
              label="adult"
            />
            <PassengerFieldButtons
              increment={() =>
                setFieldValue('passengers.child', values.passengers.child + 1)
              }
              decrement={() =>
                setFieldValue(
                  'passengers.child',
                  values.passengers.child > 0 ? values.passengers.child - 1 : 0
                )
              }
              value={values.passengers.child}
              label="child"
            />
            <PassengerFieldButtons
              increment={() =>
                setFieldValue('passengers.infant', values.passengers.infant + 1)
              }
              decrement={() =>
                setFieldValue(
                  'passengers.infant',
                  values.passengers.infant > 0
                    ? values.passengers.infant - 1
                    : 0
                )
              }
              value={values.passengers.infant}
              label="infant"
            />
            <button
              onClick={() => setOpen(false)}
              className="px-3 py-1 m-4 text-sm font-bold bg-blue uppercase text-white w-auto"
            >
              Done
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
