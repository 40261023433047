import React from 'react';

import WebpImgWithFallback from '../WebpImgWithFallback';
import ModalModel_webp from './img/webp/popup-girl.webp';
import ModalModel_png from './img/png/popup-girl.png';
import { useWindowSize } from 'hooks/useWindowSize';

export default function ModalLady({ setShowModal, setClosed }) {
  const size = useWindowSize();

  return (
    <div className="m-2 w-full shadow-md">
      <div
        className="md:py-10 py-4 pl-10 pr-4 flex justify-between"
        style={{
          backgroundImage: 'linear-gradient(to right, #3490dc, #1c3d5a'
        }}
      >
        <div>
          <h3 className="text-white text-3xl font-thin">
            Want help finding the best deal?
          </h3>
          <p className="text-white text-base mt-2">
            Discover unpublished fares by calling our Toll Free Number.
          </p>
        </div>
        <i
          onClick={() => {
            setShowModal(false);
            setClosed(true);
          }}
          className="cursor-pointer text-grey text-5xl material-icons"
        >
          close
        </i>
      </div>
      <div className="flex relative py-4 pb-10 px-10 bg-white">
        <div className="md:w-2/3">
          <p className="md:text-2xl text-xl text-grey-dark">
            Talk to one of our experienced Travel Agents to get best airfares!
          </p>
          <h3 className="mt-6 md:text-3xl text-xl text-grey-darkest">
            You may save up to 70%.
          </h3>
          <div className="p-4 my-6 shadow-md rounded border md:w-3/5 items-center">
            <p className="my-1 text-xl text-center">Call now to book</p>
            <h3 className="mb-6 mt-3 md:text-3xl text-2xl text-grey-darkest text-center">
              1-888-221-6550
            </h3>
            <div className="relative">
              <div className="absolute pin flex items-center justify-center my-4">
                <a href="tel:1-888-221-6550">
                  <button
                    type="button"
                    className="py-2 px-6 text-xl font-bold rounded bg-blue text-white uppercase hover:bg-white hover:text-blue border border-blue"
                    style={{ transition: 'all 125ms ease-in' }}
                  >
                    Call Now
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        {size.width > 768 && (
          <WebpImgWithFallback
            imgClass="invisible md:visible absolute pin-r pin-b mr-12"
            style={{ width: '283px' }}
            webp={ModalModel_webp}
            fallback={ModalModel_png}
            alt="Woman in white suit with headset and arms crossed"
          />
        )}
      </div>
    </div>
  );
}
