import React from 'react';
import WebpImgWithFallback from '../WebpImgWithFallback';

import aus_webp from './img/webp/aus.webp';
import usa_webp from './img/webp/usa.webp';
import hkg_webp from './img/webp/hkg.webp';

import aus_jpg from './img/jpg/aus.jpg';
import usa_jpg from './img/jpg/usa.jpg';
import hkg_jpg from './img/jpg/hkg.jpg';

export default function CallNow(props) {
  return (
    <div className="md:ml-0 ml-20" {...props}>
      <h2 className={`text-3xl font-thin ${props.fontColor}`}>
        Call Now to Book
      </h2>
      <ul className="list-reset">
        <li>
          <div className="flex my-4">
            <WebpImgWithFallback
              className="mt-1 w-12"
              webp={usa_webp}
              fallback={usa_jpg}
              alt="USA"
            />
            <a
              className={`ml-3 mt-2 text-xl font-thin no-underline ${props.fontColor}`}
              href="tel:1 888-221-6550"
            >
              1 888-221-6550
            </a>
          </div>
        </li>
        <li>
          <div className="flex my-4">
            <WebpImgWithFallback
              className="mt-1 w-12"
              webp={aus_webp}
              fallback={aus_jpg}
              alt="AUS"
            />
            <a
              className={`ml-3 mt-2 text-xl font-thin no-underline ${props.fontColor}`}
              href="tel:02 80058470"
            >
              02 80058470
            </a>
          </div>
        </li>
        <li>
          <div className="flex my-4">
            <WebpImgWithFallback
              className="mt-1 w-12"
              webp={hkg_webp}
              fallback={hkg_jpg}
              alt="HKG"
            />
            <a
              className={`ml-3 mt-2 text-xl font-thin no-underline ${props.fontColor}`}
              href="tel:852-8192 5250"
            >
              852-8192 5250
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
}
