import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import { apiUrl } from 'config';
import * as Yup from 'yup';

import CallNow from '../Home/CallNow';
import WebpImgWithFallback from '../WebpImgWithFallback';

import cards_webp from './img/webp/cards.webp';
import cards_png from './img/png/cards.png';

export default function Footer() {
  const [subscribed, setSubscribed] = useState(false);
  const subscribeSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email')
      .required('This field is required')
  });

  const handleSubmit = data => {
    const { email } = data;
    fetch(
      'https://lktfa3zbkf.execute-api.us-west-2.amazonaws.com/dev/subscribe/GoFlyFirst',
      {
        method: 'POST',
        body: JSON.stringify({ email })
      }
    )
      .then(() => setSubscribed(true))
      .catch(e => console.error(e));
  };

  return (
    <div className="bg-blue">
      <div className="container mx-auto py-8 px-2 md:text-left text-center">
        <div className="md:flex">
          <div className="md:w-1/2">
            <h2 className="text-white font-thin">Receive Exclusive Offers</h2>
            {subscribed ? (
              <p className="text-lg text-white my-8">You've been subscribed!</p>
            ) : (
              <Formik
                initialValues={{
                  email: ''
                }}
                validationSchema={subscribeSchema}
                onSubmit={(values, action) => handleSubmit(values)}
              >
                {({ errors }) => (
                  <Form className="my-8">
                    <div className="w-3/4 mx-auto md:mx-0">
                      <Field
                        name="email"
                        aria-label="Email"
                        className={`${
                          errors.email ? 'border-2 border-red-light' : 'border'
                        } px-4 py-3 w-full focus:shadow-lg`}
                        type="text"
                        placeholder="Enter your email address"
                      />
                    </div>
                    <div className="w-32 mx-auto md:mx-0">
                      <button
                        type="submit"
                        className="py-3 px-6 text-sm mt-8 w-full border-2 rounded border-white hover:bg-white hover:text-blue text-white uppercase"
                      >
                        Subscribe
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
          <div className="md:w-1/4 text-left">
            <CallNow fontColor={'text-white'} />
          </div>
          <div className="md:w-1/4">
            <h2 className="mb-4 text-3xl text-white font-thin">We accept</h2>
            <WebpImgWithFallback
              webp={cards_webp}
              fallback={cards_png}
              alt="Credit card logos"
            />
          </div>
        </div>
      </div>
      <div className="bg-grey-darkest">
        <div className="container md:py-6 pt-6 pb-24 mx-auto p-4">
          <p className="text-white text-xs leading-loose">
            © 2018 Go Fly First. All Rights Reserved. Use of this website
            constitutes acceptance of the{' '}
            <Link className="text-white" to={'/terms-of-service.html'}>
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link className="text-white" to={'/privacy-policy.html'}>
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
