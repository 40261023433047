import React, {useEffect} from 'react';
import ContactInfo from "../../data/ContactInfo";

import WebpImgWithFallback from '../WebpImgWithFallback';
import aus_webp from './img/webp/aus.webp';
import usa_webp from './img/webp/usa.webp';
import hkg_webp from './img/webp/hkg.webp';

import aus_jpg from './img/jpg/aus.jpg';
import usa_jpg from './img/jpg/usa.jpg';
import hkg_jpg from './img/jpg/hkg.jpg';

export default function ModalCallNow({ showModal, setShowModal }) {
  useEffect(() => {
    document.addEventListener('keydown', e => {
      if (e.keyCode === 27) {
        setShowModal(false);
      }
    });
    return document.removeEventListener('keydown', e => {
      if (e.keyCode === 27) {
        setShowModal(false);
      }
    });
  }, []);

  return (
    <div
      className={`${
        showModal
          ? 'fixed opacity-100 visible md:-mt-16 block'
          : 'opacity-0 invisible'
      } w-full h-full z-50`}
      onClick={() => {
        setShowModal(false);
      }}
    >
      <div
        className={`${
          showModal ? 'opacity-50 visible' : 'opacity-0 invisible'
        } absolute w-full h-screen bg-black`}
        style={{ transition: 'all 125ms ease-in' }}
        onClick={() => {
          setShowModal(false);
        }}
      />
      <div
        className={`${
          showModal ? 'opacity-100 visible' : 'opacity-0 invisible -mt-32'
        } absolute pin mx-auto flex md:items-center md:w-1/2 z-10`}
        style={{ transition: 'all 500ms ease-in' }}
      >
        <div
          className="mx-auto rounded w-64 bg-white shadow-md mt-2"
          style={{ width: '227px', height: '248px' }}
        >
          <div className="flex border-b">
            <h3 className="py-6 pl-4 text-xl font-thin">Call Now to Book</h3>
            <h2
              onClick={() => {
                setShowModal(false);
              }}
              className="font-thin text-4xl mt-3 mr-4 ml-auto text-grey-light"
            >
              ×
            </h2>
          </div>
          <div className="p-4">
            <div className="flex pb-2">
              <WebpImgWithFallback
                className="mt-1 w-10"
                webp={usa_webp}
                fallback={usa_jpg}
                alt="USA"
              />
              <a
                  className="text-xl font-thin mt-2 ml-2 no-underline"
                  href={ContactInfo.USA.href}
              >
                {ContactInfo.USA.simpleText}
              </a>
            </div>
            <div className="flex py-2">
              <WebpImgWithFallback
                className="mt-1 w-10"
                webp={aus_webp}
                fallback={aus_jpg}
                alt="AUS"
              />
              <a
                  className="text-xl font-thin mt-2 ml-2 no-underline"
                  href={ContactInfo.AUS.href}
              >
                {ContactInfo.AUS.simpleText}
              </a>
            </div>
            <div className="flex py-2">
              <WebpImgWithFallback
                className="mt-1 w-10"
                webp={hkg_webp}
                fallback={hkg_jpg}
                alt="HKG"
              />
              <a
                  className="text-xl font-thin mt-2 ml-2 no-underline"
                  href={ContactInfo.HKG.href}
              >
                {ContactInfo.HKG.simpleText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
