import React from 'react';
import destinations from 'data/destinations.json';
import { ErrorMessage } from 'formik';
import DateField from './DateField';
import DestinationField from './DestinationField';
import shortid from 'shortid';

export default function MultiCityField({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  index,
  push,
  remove
}) {
  const touchedError = field =>
    errors.destinations &&
    errors.destinations[index] &&
    errors.destinations[index][field] &&
    touched &&
    touched.destinations &&
    touched.destinations[index] &&
    touched.destinations[index][field];

  return (
    <div className="md:flex w-full">
      <div className="md:w-1/3 m-2 relative">
        <DestinationField
          name={`destinations[${index}].from`}
          placeholder="From"
          setFieldValue={setFieldValue}
          ariaLabel={`From ${index}`}
          error={touchedError('from')}
        />
      </div>
      <div className="md:w-1/3 m-2 relative">
        <DestinationField
          name={`destinations[${index}].to`}
          placeholder="To"
          setFieldValue={setFieldValue}
          ariaLabel={`To ${index}`}
          error={touchedError('to')}
        />
      </div>
      <div className="md:w-1/6 m-2">
        <DateField
          placeholder="Depart"
          name={`destinations[${index}].depart`}
          value={values.destinations[index].depart}
          setFieldValue={setFieldValue}
          minDate={
            !!values.destinations[index - 1] &&
            values.destinations[index - 1].depart
              ? values.destinations[index - 1].depart
              : values.depart || new Date()
          }
          error={touchedError('depart')}
          setFieldTouched={setFieldTouched}
        />
      </div>
      <div className="md:w-1/6 w-full">
        {index === 0 ? (
          <button
            className="p-2 m-2 rounded bg-blue-dark text-white text-center"
            type="button"
            onClick={() =>
              push({ id: shortid.generate(), from: '', to: '', depart: null })
            }
            aria-label="Add segment"
          >
            + Add Segment
          </button>
        ) : (
          <button
            className="w-6 pb-1 m-2 rounded bg-red-light text-white text-center"
            type="button"
            onClick={() => remove(index)}
            aria-label=""
          >
            -
          </button>
        )}
      </div>
    </div>
  );
}
